<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="btn">
			<a-button slot="tabBarExtraContent" type="primary" v-t.preserve="'table.applyInfo.actions.add'" @click="AddApplication"> </a-button>
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a @click="Edit(columnData.id)">{{ $t('page.edit') }}</a>
			<a-divider type="vertical" />
			<a @click="Detail(columnData.id)">{{ $t('page.checkSubmit') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiGetApplyList } from '@/api/loan'
export default {
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.applyInfo.cloums.caseStatus',
					type: 'select',
					validateField: ['statuses'],
					selectOption: ['apply_info_input', 'apply_info_check_fail', 'apply_info_client_reject'],
					prefix: 'table.applyInfo.recordCloums.nodeStatus'
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityCard']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.applyInfo.cloums.creator',
					type: 'input',
					validateField: ['creatorName']
				},
				{
					label: 'table.applyInfo.cloums.createTime',
					type: 'range-picker',
					validateField: ['createTime', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return `${o.disbursePrefix ? `${o.disbursePrefix}-` : ''}${o.casePrefix}-${o.caseId}`
					}
				},
				{
					// 状态
					title: this.$t('table.applyInfo.cloums.status'),
					dataIndex: 'statusCode',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.applyInfo.recordCloums.nodeStatus.${v}`)
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityCard',
					align: 'center'
				},
				{
					// 申请金额
					title: this.$t('table.applyInfo.cloums.loanAmountRequested'),
					dataIndex: 'loanApplicationAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_slot' }
				},
				{
					// 创建人ID
					title: this.$t('table.applyInfo.cloums.creator'),
					dataIndex: 'creatorName',
					align: 'center'
				},
				{
					// 创建时间
					title: this.$t('table.applyInfo.cloums.createTime'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiGetApplyList(initParams)
		},
		AddApplication() {
			this.$router.push('addapplication')
		},
		// 编辑
		Edit(id) {
			this.$router.push({
				path: 'editapplication',
				query: {
					id,
					from: '/home/loandata/applicationlist'
				}
			})
		},
		// 查看详情
		Detail(id) {
			this.$router.push({
				path: 'applicationdetail',
				query: {
					id,
					from: '/home/loandata/applicationlist'
				}
			})
		}
	}
}
</script>
